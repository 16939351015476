<template>
    <div class="p-grid">
      <div class=" p-col-12 card card-w-title">
        <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>
        <Toolbar class="p-col-12">

          <template #left>

            <div class="p-fluid filter p-ml-2">

              <div class="p-grid p-fluid filter p-ml-2">


                <div class="p-field   p-fluid  p-mr-2" v-if="isAdmin">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedC" :options="countries" optionLabel="country"
                                                 @change="selectedM=null;selectedW=null;filter()"/>
                                        <label for="country">País</label>
                                    </span>
                </div>
                <div class="p-field   p-fluid  p-mr-2">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedM" :options="months" optionLabel="month_process"
                                                 @change="selectedW=null;pLiquidations=[];filter()"/>
                                        <label for="months">Mes</label>
                                    </span>
                </div>
                <div class="p-field   p-fluid  p-mr-2">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedW" :options="weeks" optionLabel="week_process"
                                                 @change="pLiquidations=[];filter()"/>
                                        <label for="weeks">Semana</label>
                                    </span>
                </div>

                <div class="p-field   p-fluid  p-ml-2">
                  <Button label="Limpiar" icon="pi pi-undo" class="p-button-secondary p-mr-2"
                          @click="reload"/>
                </div>
              </div>

            </div>
          </template>
        </Toolbar>
      </div>
      <DataTable :value="pLiquidations" :paginator="true" dataKey="id"
                 v-model:selection="currentItem" selectionMode="single"
                 @row-select="selectData($event)"
                 paginatorPosition="bottom"
                 :lazy="true"
                 :totalRecords="page.total"
                 :rows="page.per_page"
                 :loading="loading"
                 @page="onPage($event)">
        <Column header="Cons. No" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">
              <router-link :to="'/my-pays/liquidations/detail/'+slotProps.data.id">{{ slotProps.data.id }}</router-link>
            </div>
          </template>
        </Column>
        <Column header="País" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ slotProps.data.country.country_name }}</div>
          </template>
        </Column>
        <Column header="Nombres Asesor" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ slotProps.data.employee_name }}</div>
          </template>
        </Column>
        <Column header="Mes" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ slotProps.data.liquidation_month?.month_process }}
            </div>
          </template>
        </Column>
        <Column header="Semana" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ slotProps.data.liquidation_week?.week_process }}</div>
          </template>
        </Column>
        <Column header="Total" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center"> {{ formatPrice(slotProps.data.totals.total) }}</div>
          </template>
        </Column>
        <Column header="Neto a Pagar" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center"> {{ formatPrice(slotProps.data.totals.net_to_pay) }}</div>
          </template>
        </Column>
        <Column header="Fecha Generacion" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ $filters.dateFormat(slotProps.data.generate_at) }}</div>
          </template>
        </Column>
        <Column header="Estado" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">
              <Tag class="p-mr-2" rounded icon="pi pi-info-circle" :value="slotProps.data.status"></Tag>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
</template>

<script>
import service from '../../../service/myPays.service'
import mw from '../../../service/mw.service'
import {mapGetters, mapState} from "vuex";
export default {
  name: "MyPaysLiquidationIndex",
  props: {
    enroll: Object,
    edit: Boolean,
  },
  data() {
    return {
      columns: [
        {field: 'code', header: 'Codigo'},
        {field: 'date_payment', header: 'Fecha'},
      ],
      page: {
        page: 1,
        per_page: 9,
        total: 0,
        order: "desc"
      },
      pLiquidations: [],
      data: {},
      selectedWeek: null,
      selectedMonth: null,
      submitted: false,
      loading: false,
      dialogo: false,
      verifyDataDialog: false,

      selected: false,
      currentItem:null,
      selectedC: null,
      selectedM: null,
      selectedW: null,
      search: '',
      countries: [],
    }
  },
  methods: {
    onPage(event) {
      this.page.page = event.page + 1;
      this.getData()
    },

    enable() {
      this.selected = true
    },
    getData(params = {}) {
      this.loading = true;
      const config = {
        ...this.page,
        ...params
      };
      mw.getMWLiq().then(x => {
        this.countries = x.data
        if (!this.isAdmin) {
          this.selectedC = this.countries[0];
        }
      })
      service.liquidation(null, config).then(x => {
        this.pLiquidations = x.data.data;
        this.page.total = x.data.total;

        if(this.page.total===0){
          this.$toast.add({severity: 'error', summary: 'Atención', detail: 'No hay registros disponibles', life: this.$utils.toastLifeTime()});
        }

      }).finally(() => this.loading = false)
    },
    reload() {
      this.selectedC = null;
      this.selectedW = null;
      this.selectedM = null;
      this.filters = {};
      this.search = '';
      this.getData();
    },
    formatPrice(value) {
      var val = (value / 1).toFixed(2).replace('.', ',')
      if (value !== 0) {
        val = "$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      }
      return val
    },
    filter() {
      this.loading = true;
      const country_id = this.selectedC ? this.selectedC.id : undefined;
      const month_id = this.selectedM ? this.selectedM.id : undefined;
      const week_id = this.selectedW ? this.selectedW.id : undefined;
      const params = {
        month_id, week_id,country_id
      };
        this.getData(params)

    },

    selectData() {
      this.$router.push('liquidations/detail/' + this.currentItem.id,);
    },
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
    ...mapGetters('bread', ['breadcrumb']),
    months() {
      const result= this.selectedC ? this.selectedC.months : [];
      if(result.length===0 && this.selectedC){
        this.$toast.add({severity: 'warn', summary: 'Atención', detail: 'No existen meses asociados al país', life: this.$utils.toastLifeTime()});
      }
      return result;
    },
    weeks() {
      const result= this.selectedM ? this.selectedM.weeks : [];
      if(result.length===0  && this.selectedM){
        this.$toast.add({severity: 'warn', summary: 'Atención', detail: 'No existen semanas asociadas al mes', life: this.$utils.toastLifeTime()});
      }
      return result;
    }
  },
  mounted() {
      this.$store.dispatch('bread/setItems', [
        {label: 'Lista de mis liquidaciones', url: "javascript:void(0);"},
      ]);
      this.getData();

  }
}
</script>

<style scoped lang="scss">

.p-fieldset {
  margin-top: 15px;
}

.p-field {
  padding-top: 15px;
}

.p-dialog-footer {
  padding: 0;
  margin-bottom: -15px;
}

.p-grid {
  margin-top: 15px;
}

.p-toolbar {
  background: none;
  border: none;
}

.p-inputtext {
  width: 100%;
}

.p-autocomplete {
  height: 30px !important;
}

.df, .df #status, .p-fluid .p-dropdown {
  height: 100%;
}

.p-selectable-row td:first-child, .uc {
  padding-left: 1.5rem !important;
  display: inline-block;
}

.center, .p-dialog .p-dialog-footer {
  text-align: center;
}
</style>
